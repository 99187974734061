export const mineConfig = [
  {
    field: 'trackType',
    title: '跟进类型',
    type: 'simple',
    defaultVal: '',
    componentName: 'TagSelect',
    options: [],
    getOptions(dict) {
      return dict.trackType || []
    }
  },
  {
    field: 'isAccompany',
    title: '是否陪访人员',
    type: 'simple',
    defaultVal: '',
    componentName: 'TagSelect',
    options: [{ text: '是', value: '1' }, { text: '否', value: '0' }]
  },
  {
    field: 'accompanyId',
    title: '陪访人员',
    type: 'string',
    defaultVal: '',
    componentName: 'GoTree',
    treeType: 'accompanyId',
    hidden(data) {
      if (data['isAccompany'] === '1') {
        return false
      }
      return true
    }
  },
  {
    field: 'effective',
    title: '是否有效',
    type: 'simple',
    defaultVal: '',
    componentName: 'TagSelect',
    options: [{ text: '是', value: '1' }, { text: '否', value: '0' }]
  },
  {
    field: 'trackReliable',
    title: '进程推进',
    type: 'number',
    defaultVal: '',
    componentName: 'GrooveSlider',
    options: [],
    getOptions(dict) {
      const options = JSON.parse(JSON.stringify(dict.reliable || []))
      const reg = /\d+\%/
      options.forEach(option => {
        const list = option.text.match(reg)
        option.percentage = list && list[0]
      })
      return options
    },
    getValue(options, val) {
      let value = ''
      options.forEach(option => {
        if (val === option.percentage) {
          value = option.value
        }
      })
      return value
    },
    step: 10,
    titleBock: {
      numPre: '靠谱度',
      unit: '%',
      placeholder: '请选择靠谱度'
    }
  },
]

export const branchConfig = [
  {
    field: 'trackType',
    title: '跟进类型',
    type: 'simple',
    defaultVal: '',
    componentName: 'TagSelect',
    options: [],
    getOptions(dict) {
      return dict.trackType || []
    }
  },
  {
    field: 'sellUserId',
    title: '销售',
    type: 'string',
    defaultVal: '',
    componentName: 'GoTree',
    treeType: 'sellUserId'
  },
  {
    field: 'orgId',
    title: '所属部门',
    type: 'string',
    defaultVal: '',
    componentName: 'GoTree',
    treeType: 'orgId'
  },
  {
    field: 'branchOrgId',
    title: '分公司',
    type: 'string',
    defaultVal: '',
    componentName: 'OldCompBranch2'
  },
  {
    field: 'isAccompany',
    title: '是否陪访人员',
    type: 'simple',
    defaultVal: '',
    componentName: 'TagSelect',
    options: [{ text: '是', value: '1' }, { text: '否', value: '0' }]
  },
  {
    field: 'accompanyId',
    title: '陪访人员',
    type: 'string',
    defaultVal: '',
    componentName: 'GoTree',
    treeType: 'accompanyId',
    hidden(data) {
      if (data['isAccompany'] === '1') {
        return false
      }
      return true
    }
  },
  {
    field: 'effective',
    title: '是否有效',
    type: 'simple',
    defaultVal: '',
    componentName: 'TagSelect',
    options: [{ text: '是', value: '1' }, { text: '否', value: '0' }]
  },
  {
    field: 'trackReliable',
    title: '进程推进',
    type: 'number',
    defaultVal: '',
    componentName: 'GrooveSlider',
    options: [],
    getOptions(dict) {
      const options = JSON.parse(JSON.stringify(dict.reliable || []))
      const reg = /\d+\%/
      options.forEach(option => {
        const list = option.text.match(reg)
        option.percentage = list && list[0]
      })
      return options
    },
    getValue(options, val) {
      let value = ''
      options.forEach(option => {
        if (val === option.percentage) {
          value = option.value
        }
      })
      return value
    },
    step: 10,
    titleBock: {
      numPre: '靠谱度',
      unit: '%',
      placeholder: '请选择靠谱度'
    }
  },
]

