<template>
  <div
    class="search-header"
    @click="$refs.input.focus()"
  >
    <div
      class="search_box"
    >
      <i class="icon-joyo search_icon">&#xe6f0;</i>
      <form
        class="search_input"
        action="javascript:void 0"
      >
        <input
          ref="input"
          :value="value"
          class="autofocus"
          :placeholder="placeholder"
          maxlength="50"
          @compositionstart="cpLock=true"
          @compositionend="cpLock=false"
          @keyup.13="getLists"
          @input="clear"
        >
      </form>
      <i
        v-if="value"
        class="icon-joyo search_icon showhidden"
        @click="clearValue"
      >&#xe6ec;</i>
    </div>
  </div>
</template>

<script>
import MonitorKeyboard from '@/utils/monitorKeyboard'

export default {
  model: {
    prop: 'value',
    event: 'updateValue'
  },
  props: {
    placeholder: {
      type: String,
      default: '请输入搜索内容'
    },
    ruleFunc: {
      type: Function,
      default () {
        return true;
      }
    },
    value: {
      type: [String, Object, Array, Number],
      default: ''
    }
  },
  data() {
    return {
      cpLock: false,
      // 处理安卓机 光标不消失问题
      monitorKeyboard: null
    };
  },
  mounted() {
    this.getKeyboardState()
  },
  beforeDestroy() {
    this.monitorKeyboard.onEnd()
  },
  methods: {
    getLists() {
      if (this.ruleFunc(this.value)) {
        this.$emit('getLists', this.value);
      }
    },
    clear(event) {
      this.$emit('updateValue', event.target.value)
      // vue中解决模糊搜索输入中文时--未输入完成时触发input事件
      if (this.cpLock) {
        return true;
      }
      // if (this.value === '') {
      //   this.$emit('getLists', this.value);
      // }
    },
    clearValue() {
      // this.value = '';
      this.$emit('updateValue', '')
      this.$emit('getLists', '');
    },
    getKeyboardState() {
      this.monitorKeyboard = new MonitorKeyboard()
      this.monitorKeyboard.onStart();
      // 监听虚拟键盘弹出事件
      this.monitorKeyboard.onShow(() => {
      })
      // 监听键盘收起的事件
      this.monitorKeyboard.onHidden(() => {
        this.$refs.input.blur()
      })
    }
  }
};
</script>

<style  lang="scss"  scoped>
.search-header{
  height:58px;
  overflow: hidden;
  background-color: $color-FFF;
}
.search_box{
  display: flex;
  flex: 1;
  align-items:center;
  justify-content:flex-start;
  height: 34px;
  margin: 16px 15px 8px 15px;
  background-color: #F5F5F7;
  border-radius:4px ;
  input {
    width: 100%;
    height: 16px;
    font-size: 14px;
    font-family: sans-serif, Avenir, Helvetica, Arial;
    line-height: 16px;
    background-color: rgba(0, 0, 0, 0);
    border: 0;  // 去除未选中状态边框
    outline: none; // 去除选中状态边框// 透明背景
  }
  .search_icon{
    width:24px;
    height: 24px;
    margin:0 8px 0 6px;
    color:$color-CCC;
    font-size: 22px ;
    line-height: 24px;
    text-align: center;
  }
  .search_input{
    flex: 1;
    font-size: 12px;
  }
  .showhidden{
    // color: $color-E6;
    font-size: $font-16;
  }
}
</style>
