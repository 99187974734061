<template>
  <div class="section follow-manage">
    <complex
      ref="complex"
      class="test"
      :class="{'btn-show': isAddShow}"
      :offset-top="58"
      :data="tableData[tabItem.code]"
      :load-enable="loadEnable"
      :refresh-enable="loadEnable"
      @pullingUp="onPullingUp"
      @pullingDown="onPullingDown"
      @diffChange="diffChange"
    >
      <template slot="header">
        <div class="complex-header">
          <show-search
            v-if="scrollDiff < 1"
            v-model="requestData.custCn"
            search-type="custName"
            placeholder="请输入客户名称"
            :need-sreen="false"
            @getLists="refreshLoad"
          />
        </div>
      </template>
      <template slot="sticky-ele">
        <show-search
          v-if="scrollDiff >= 1"
          v-model="requestData.custCn"
          search-type="custName"
          placeholder="请输入客户名称"
          :need-sreen="false"
          @getLists="refreshLoad"
        />
        <condition-info
          v-model="conditionValue"
          :tabs="tabList"
          :filter-num="filterNum"
          :fast-list="fastList"
          :is-tab-show="scrollDiff < 1"
          @tabChange="tabChange"
          @fastChange="fastChange"
          @toFilter="toFilter"
        />
      </template>
      <template slot="content">
        <div class="content">
          <template
            v-for="(items, i) in tableData"
          >
            <div
              v-if="tabItem.code === i"
              :key="i"
              class="list-wrapper"
            >
              <error-page
                v-if="!items.length"
                class="errorHeight"
              />
              <ul
                v-for="(item, indexs) in items"
                :key="indexs"
                class="out-box"
                @click="toDetail(item.id, item.mlabel)"
              >
                <card-follow :data="item.mainObj">
                  <div v-if="item.mlabel === '我的跟进'" />
                </card-follow>
              </ul>
            </div>
          </template>
        </div>
      </template>
    </complex>
    <div class="bottom-postion">
      <cube-button
        v-if="btnAid.includes('add')"
        class="btn"
        @click="toAdd"
      >
        添加跟进
      </cube-button>
    </div>
    <filter-model
      :show.sync="filterModelShow"
      :data.sync="filterData"
      :num.sync="filterNum"
      :nodes="modelConfig"
      :dict="dictList"
      @confirm="filterConfirm"
    />
  </div>
</template>

<script >
import { mapGetters, mapMutations } from 'vuex';

import { followList } from '_api/follow';
import complex from '_c/complex'
import conditionInfo from '_c/condition/filter.vue'
import errorPage from '_c/errorPage';
import FilterModel from '_c/fliter-model'
import showSearch from '_c/search/search-model';
import cardFollow from '_c/tab-card/card-follow';

import { debounce, findValueByKey } from '@/lib/until';
import btnAuth from '@/mixins/btnAuth';
import { branchConfig, mineConfig } from './filterConfig'

export default {
  name: 'FollowManage',
  components: {
    complex,
    conditionInfo,
    showSearch,
    cardFollow,
    errorPage,
    FilterModel
  },
  mixins: [btnAuth],
  data() {
    return {
      aid: [],
      tableData: [[], []],
      tabList: [],
      requestData: {
        custCn: '',
        sellUserId: '',
        orgId: '',
        branchOrgId: '',
        trackType: '',
        isAccompany: '',
        accompanyId: '',
        effective: '',
        trackReliable: '',
        pageNum: 1,
        pageSize: 30
      },
      index: 0,
      scrollDiff: 0,
      conditionValue: {
        tabItem: { code: 0 },
        fastItem: { code: '' }
      },
      filterModelShow: false,
      filterData: {},
      filterNum: 0,
      // 在路由撤退时用来触发子组件数据更新
      activatedNum: 0
    };
  },
  computed: {
    ...mapGetters('follow', ['filterObj', 'aidList']),
    ...mapGetters(['dictList']),
    loadEnable() {
      return this.tableData[this.index] ? !!this.tableData[this.index].length : true;
    },
    tabItem() {
      return this.conditionValue.tabItem || {}
    },
    fastItem() {
      return this.conditionValue.fastItem || {}
    },
    fastList() {
      return []
    },
    isAddShow() {
      const item = this.tabList[this.tabItem.code]
      return item && (item.label.includes('我的'))
    },
    modelConfig() {
      let config = []
      if (this.isMineTab(this.tabItem.code)) {
        config = mineConfig
      } else if (this.isBranchTab(this.tabItem.code)) {
        config = branchConfig
      }
      return config
    }
  },
  provide() {
    return {
      manageContainer: this
    }
  },
  mounted() {
    this.init();
  },
  activated() {
    this.activatedNum++
    // this.fromFilter();
  },
  methods: {
    ...mapMutations(['CLEAR_FILTER', 'SET_KEEPALIVE']),
    getLists(requestData, aid, index, mlabel, type) {
      if (!this.tableData[index].length) this.$loading.show();
      followList(requestData, aid[index]).then((res) => {
        this.$loading.hide();
        if (res.flag) {
          const tableData = [[], []];
          const list = res.data.pageInfo.list || [];
          if (list.length > 0) {
            if (type === 'upload') {
              tableData[index] = this.tableData[index].concat(list);
            } else {
              tableData[index] = list;
            }
            tableData[index].forEach(item => {
              item.mlabel = mlabel;
              item.mainObj = {
                time: item.trackDate ? item.trackDate.substring(5, item.trackDate.length) : '',
                year: item.trackDate ? item.trackDate.substring(0, 4) : '',
                title: item.custCn,
                trackType: findValueByKey(this.dictList.trackType, item.trackType),
                mainList: [
                  `<span>${item.comboName !== null ? '商机产品：' + item.comboName : ''}</span>`,
                  `<span style="color:#00A4FE">进程推进：${findValueByKey(this.dictList.reliable, item.trackReliable)}</span>`
                ].filter(Boolean),
                userTitle: item.sellerUserTitle,
                branchOrgTitle: item.branchOrgTitle,
                orgTitle: item.orgTitle
              };
            });
            this.$set(this.tableData, index, tableData[index]);
            this.tabList[index].tableData = tableData[index];
            if (this.$refs.complex) this.$refs.complex.upload = true;
          } else {
            if (requestData.pageNum === 1) {
              this.$set(this.tableData, index, []);
            } else {
              if (this.$refs.complex) this.$refs.complex.upload = false;
              this.$refs.complex.onPullingUp();
            }
          }
        } else {
          this.$showToast(res.errorMsg);
        }
      });
    },
    getIndex(index) {
      this.index = index;
      this.$set(this.tableData, index, []);
      // this.CLEAR_FILTER('follow');
      this.filterData = {}
      const cleard = debounce(this.clear, 500);
      cleard();
    },
    clear() {
      for (const i in this.requestData) {
        this.requestData[i] = '';
      }
      this.requestData.pageNum = 1;
      this.requestData.pageSize = 10;
      this.tableData[this.index] = [];
      this.refreshLoad();
    },
    toAdd() {
      this.$router.push(
        {
          name: 'AddeditFollow'
        }
      );
    },
    toFilter() {
      this.filterModelShow = true
      // const index = this.tabList[this.index].label === '我的跟进' ? '0' : '1';
      // this.$router.push(
      //   {
      //     name: 'follow-filter',
      //     params: {
      //       i: index
      //     }
      //   }
      // );
    },
    toDetail(id, mlabel) {
      this.SET_KEEPALIVE([`${this.$route.name}`]);
      const index = mlabel === '我的跟进' ? '0' : '1';
      this.$router.push(
        {
          name: 'follow-details',
          params: {
            followId: id,
            type: index
          }
        }
      );
    },
    init() {
      this.getGetters();
      this.tabList = [];
      this.aidList.forEach((item, i) => {
        this.tabList.push({ label: item.mlabel, value: i, code: i, aidList: item.actions, num: '' });
        item.actions.forEach(itemA => {
          switch (itemA.acode) {
            case 'query':
              this.aid.push(itemA.aid);
              break;
          }
        });
        this.getLists(this.requestData, this.aid, i, item.mlabel);
      });
    },
    fromFilter() {
      const i = this.index;
      this.getGetters();
      this.getLists(this.requestData, this.aid, i, this.aidList[i].mlabel);
    },
    // 获取vuex数据
    getGetters() {
      // for (const i in this.filterObj) {
      //   this.requestData[i] = this.filterObj[i].value || '';
      // }
      this.getAuthBtn();
    },
    refreshLoad(val, type) {
      const i = this.index;
      // const custCn = this.$refs.searchRef.value || val;
      this.getLists({ ...this.requestData }, this.aid, i, this.aidList[i].mlabel, type);
      this.getAuthBtn();
    },
    tabChange() {
      this.conditionValue.fastItem = { code: '' }
      this.getIndex(this.tabItem.code)
      this.$nextTick(() => {
        this.$refs.complex.allRefresh()
      })
    },
    fastChange() {
      this.requestData.pageNum = 1
      this.fromFilter()
      this.$nextTick(() => {
        this.$refs.complex.allRefresh()
      })
    },
    // 下拉刷新
    onPullingDown() {
      this.requestData.pageNum = 1
      this.refreshLoad('', 'refresh')
    },
    // 上拉加载
    onPullingUp() {
      this.requestData.pageNum++
      this.refreshLoad('', 'upload')
    },
    diffChange(diff) {
      if (diff > 1) {
        this.scrollDiff = 1
      } else {
        this.scrollDiff = diff
      }
    },
    isMineTab(code) {
      const item = this.tabList[code]
      return item && (item.label.includes('我的'))
    },
    isBranchTab(code) {
      const item = this.tabList[code]
      return item && (item.label.includes('部门'))
    },
    filterConfirm() {
      this.setRequestData()
      const i = this.index;
      this.requestData.pageNum = 1;
      this.requestData.pageSize = 10;
      this.getLists(this.requestData, this.aid, i, this.aidList[i].mlabel);
      this.filterModelShow = false
    },
    setRequestData() {
      let accompanyIdHidden = false
      this.modelConfig.forEach(config => {
        if (config.field === 'accompanyId') {
          accompanyIdHidden = config.hidden(this.filterData)
        }
      })
      for (const key in this.filterData) {
        if (['oppoLevel', 'trackReliable'].includes(key)) {
          this.requestData[key] = `${this.filterData[key]}`
        } else if (key === 'accompanyId') {
          this.requestData[key] = accompanyIdHidden ? '' : this.filterData[key]
        } else {
          this.requestData[key] = this.filterData[key]
        }
      }
    }
  }
};
</script>

<style lang="scss" scoped  src="@/assets/css/components/btn-bottom.scss"></style>
<style  lang="scss"  scoped>
.complex-header{
  width:100%;
  height: 58px;
  overflow: hidden;
  background-color: #fff;
  box-shadow: none;
}
.list-wrapper{
  .out-box:first-child{
    margin-top:10px;
    .card-box{
      padding-top:19px ;
    }
  }
  .out-box:last-child{
    .card-box{
      padding-bottom:24px;
    }
  }
}
.errorHeight{
  height:calc(100vh - 118px);
}
.follow-manage {
  /deep/.sticky-view-complex.btn-show {
    height: calc(100% - 70px);
  }
}
</style>
