<template>
  <div class="card-box">
    <div class="card-cont">
      <div class="card-time">
        <div class="card-data">{{ data.time ? data.time : '—/—' }}</div>
        <div class="card-year">{{ data.year ? data.year : '——'}}</div>
        <div class="card-line"></div>
      </div>
      <div class="card-content">
        <div class="card-company">
          <div class="card-aa">
            {{ data.title }}
          </div>
          <div class="card-ab">{{ data.trackType}}<i class="icon-joyo arrows">&#xe66c;</i></div>
        </div>
        <div class="card-thing" v-for="(item, index) of data.mainList" :key="index" v-html="item"></div>
        <slot>
          <!-- <div class="card-d">
            <div class="card-da">{{data.userTitle}}</div>
            <div class="card-da">{{`${data.branchOrgTitle ? data.branchOrgTitle + '-' : ''}${data.orgTitle ? data.orgTitle : ''}`}}</div>
          </div> -->
          <div class="card-thing">{{data.userTitle}}</div>
          <div class="card-thing">{{`${data.branchOrgTitle ? data.branchOrgTitle + '-' : ''}${data.orgTitle ? data.orgTitle : ''}`}}</div>
        </slot>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    data: {
      type: Object
    }
  }
};
</script>

<style lang="scss" scoped>
.card-box{
  width:100%;
  background-color: $color-FFF;
  padding-top:10px ;
  .card-cont{
    display: flex;
    justify-content: flex-start;
    padding: 0 14.5px;
    text-align: left;
    .card-time{
      display: flex;
      flex-direction: column;
      line-height: 1.5;
      .card-data{
        color:$color-333;
        font-size: 18px;
        font-weight: 600;
        margin-bottom: 10px;
      }
      .card-year{
        color:$color-999;
        font-size:$font-12;
        margin-bottom:10px ;
      }
      .card-line{
        width: 1px;
        flex:1;
        background: $color-E6;
        margin: 0 auto;
      }
    }
    .card-content{
      flex: 1;
      margin: 0 0 24px 20px;
      .card-a{
        font-size:$font-15;
        color:$color-333;
        margin-bottom: 9px;
      }
      .card-company {
        display: flex;
        justify-content: space-between;
        margin-bottom: 9px;
        .card-aa {
          color: $color-333;
          font-size: $font-15;
          font-weight: 600;
          line-height: 1.5;
        }
        .card-ab{
          color:$color-999;
          font-size:$font-14;
          flex:0 0 60px;
          text-align: right;
          line-height: 1.5;
          .arrows{
            color:$color-CCC;
            font-size:$font-12;
            margin-left: 5px;
          }
        }
      }
      .card-thing{
        font-size:$font-14;
        color:$color-999;
        margin-bottom: 14.5px;
      }
      .card-d {
        display: flex;
        justify-content: space-between;
        .card-da {
          color: $color-999;
          font-size: $font-14;
        }
        .card-da:last-child {
          padding-right: 18px;
        }
      }
    }
  }
}

</style>
