import { baseRequest } from './index';

/**
 * 跟进管理列表
 * @params data Object
 * @returns MenusInfo
*/
export const followList = (data, aid) => baseRequest.post('/crmapp/oppo/track/list', data, {
  headers: {
    'aid': aid
  }
});

/**
 * 跟进详情
 * @params trackId int
 * @returns UserInfo
*/
export const followDetail = (trackId) => baseRequest.post('/crmapp/oppo/track/detail', {trackId});

/**
 * 跟进保存
 * @params data Object
 * @returns MenusInfo
*/
export const followSave = (data) => baseRequest.post('/crmapp/oppo/track/save', data);

/**
 * 跟进保存
 * @params data Object
 * @returns MenusInfo
*/
export const followUpdate = (data) => baseRequest.post('/crmapp/oppo/track/update', data);

/**
 * 商机跟进需求岗位
 * @params data Object
 * @returns MenusInfo
*/
export const getTrackPostList = () => baseRequest.post('/crmsell/track/post/list');

/**
 * 根据客户id获取所有的签到信息
 * @params custId int
 * @params sellUserId int
 * @params trackDate string
 * @returns MenusInfo
*/
export const getRegisterInfoByCustId = (custId, sellUserId, trackDate) => baseRequest.post('/crmapp/oppo/track/getRegisterInfoByCustId', {custId, sellUserId, trackDate});

/**
 * 根据商机id获取最近一条跟进记录
 * @params oppoId int
 * @returns MenusInfo
*/
export const getLatestTrackDataByOppoId = (oppoId) => baseRequest.post('/crmapp/oppo/track/getLatestTrackDataByOppoId', {oppoId});

/**
 * 获取跟进信息-编辑跳转
 * @params oppoId int
 * @returns MenusInfo
*/
export const trackDetail = (id) => baseRequest.post('/crmapp/oppo/track/trackDetail', {id});

/**
 * 根据签到id获取所有的签到附件信息
 * @params registerId int
 * @returns MenusInfo
*/
export const getRegisterFileInfoByRegisterId = (registerId) => baseRequest.post('/crmapp/custregister/getRegisterFileInfoByRegisterId', {registerId});

/**
 * 根据客户id获取所有的签到信息
 * @params custId int
 * @params trackDate stringid
 * @returns MenusInfo
*/
export const getVisitTogetherByCustId = (custId, trackDate) => baseRequest.post('/crmapp/custregister/getVisitTogetherByCustId', {custId, trackDate});

/**
 * 提醒销售待跟进商机信息-详情
 * @params data Object
 * @returns MenusInfo
*/
export const noticeSellerTrackDetail = (oppoId) => baseRequest.post('/crmapp/oppo/track/noticeSellerTrackDetail', {oppoId});
