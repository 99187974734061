export default {
  data() {
    return {
      btnAid: []
    };
  },
  computed: {
    listBtn() {
      if (this.index === 0 && this.btnAid.includes('add')) {
        return true;
      } else if (this.index === 1 && this.btnAid.includes('add')) {
        return true;
      } else {
        return false;
      }
    }
  },
  methods: {
    // 判断按钮权限
    getAuthBtn(index) {
      let i = index || this.index;
      this.btnAid = [];
      (this.aidList[i].actions || []).forEach(item => {
        this.btnAid.push(item.acode);
      });
    }
  }
};
